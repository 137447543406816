<main>
    <section class="skill-icons">
        <div class="icon-container">
            <div>
                <img src="assets/img/skills/Angular.png">
                <p>Angular</p>
            </div>
            <div>
                <img src="assets/img/skills/Typescript.png">
                <p>TypeScript</p>
            </div>
            <div>
                <img src="assets/img/skills/Javascript.png">
                <p>JavaScript</p>
            </div>
            <div>
                <img src="assets/img/skills/html.png">
                <p>HTML</p>
            </div>
            <div>
                <img src="assets/img/skills/Firebase.png">
                <p>Firebase</p>
            </div>
            <div>
                <img src="assets/img/skills/git.png">
                <p>GIT</p>
            </div>
            <div>
                <img src="assets/img/skills/css.png">
                <p>CSS</p>
            </div>
            <div>
                <img src="assets/img/skills/Api.png">
                <p>Rest-Api</p>
            </div>
            <div>
                <img src="assets/img/skills/scrum.png">
                <p>Scrum</p>
            </div>
            <div>
                <img src="assets/img/skills/material.png">
                <p>Material design</p>
            </div>
        </div>
    </section>
    <section class="skill-text">
        <h1>My skills</h1>
        <span>I have grown my frontend developments skills that have allowed me
            to create different real projects.</span>

    </section>
    <div class="line"></div>
    <a href="#contact" class="button"><span>Get in touch</span></a>

</main>
<img src="assets/img/background/GReen shadow.png" class="shadow">
