<main>
    <section class="logo">
        <a href="#aboveDefault"><img
                src="assets/img/footer/logo_neu.png"></a>
        <div>
            <a href="/imprint" (mouseenter)="mouseOver = true" (mouseleave)="mouseOver = false">Imprint</a>
            <div class="underline" *ngIf="mouseOver"></div>
        </div>
    </section>
    <section class="copyright">
        <span>&copy; Nils Boelingen 2023</span>
    </section>
    <section class="social">
        <a href="https://github.com/NilsBoelingen?tab=repositories"
            target="_blank" rel="noreferrer noopener"><img
                src="assets/img/footer/git-hub.png"></a>
        <a href="mailto:kontakt@nils-boelingen.de"><img
                src="assets/img/footer/e-mail.png"></a>
        <a href="https://www.linkedin.com/in/nils-boelingen" target="_blank"
            rel="noreferrer noopener"><img
                src="assets/img/footer/linked-in.png"></a>
    </section>

</main>
