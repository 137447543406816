import { Component } from '@angular/core';

@Component({
  selector: 'app-above-default',
  standalone: true,
  imports: [],
  templateUrl: './above-default.component.html',
  styleUrl: './above-default.component.scss'
})
export class AboveDefaultComponent {

}
