<main>
    <section class="about">
        <h1>About me</h1>
        <span class="about-me">Hi, I am a Front End Developer based in Seesen,
            Germany. I have experience in building webapps with Javascript
            and Angular. I would like to further develop my skills and am
            looking forward to a professional career as a developer. I have
            responsive web apps, business apps and a game in my portfolio. Feel
            free to try them out.</span>
        <table>
            <tr>
                <td class="vert-align-ttop">
                    <img src="assets/img/about_me/bulb.png" class="growImage">
                </td>
                <td>
                    Even as a child I found IT and programming very interesting
                    and bought my first HTML book at the age of 13.
                    Unfortunately, I didn't find an apprenticeship in IT after
                    school and then moved into other industries. Now I have
                    decided to become a programmer to turn my love of
                    technology, IT and software into a career.
                </td>
            </tr>
            <tr>
                <td class="vert-align-ttop">
                    <img src="assets/img/about_me/puzzle.png" class="growImage">
                </td>
                <td>
                    Due to my love of IT and programming, I feel at home in the
                    industry. My passion for innovative
                    technologies and my willingness to continuously learn make
                    me a dedicated team member who always strives to find
                    elegant solutions to complex challenges. My understanding of
                    abstract relationships and how computers work makes me the
                    perfect employee.
                </td>
            </tr>
        </table>
    </section>
    <section class="about-me-img">
        <div class="round-image">
            <img src="assets/img/about_me/portfolio_1.png">
        </div>
        <div class="line"></div>
    </section>
    <img src="assets/img/background/Purple shadow.png"
        class="background-vector">
</main>
