<main>
    <section>
        <div>
            <div class="left-line"></div>
            <h1 id="headline">Portfolio</h1>
            <div class="right-line" id="rightLine"></div>
        </div>
        <span>This page is a sample of my work - please feel free to try them
            out.</span>
    </section>
    <app-portfolio-item *ngFor="let project of projects; let i = index"
        [index]="i" [projects]="projects"></app-portfolio-item>
</main>
<img src="assets/img/background/Vector 5.png" class="shadow-1">
<img src="assets/img/background/GReen shadow.png" class="shadow-2">