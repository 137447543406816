<main>
    <div>
        <h4 class="uc">Under Construction</h4>
    </div>
    <section>
        <img src="assets/img/above_default/portfolio_2.png"
            class="personal-img">
        <img src="assets/img/background/Group 2.png" class="img-background">
        <div class="welcome-txt">
            <div class="rotate">
                <h1>I am</h1>
            </div>
            <div>
                <h2>Nils Boelingen</h2>
                <h3>FRONTEND DEVELOPER</h3>
            </div>
        </div>
        <a href="#contact" class="button"><span>Lets Talk</span></a>
    </section>
    <div class="bottom-container">
        <div class="social">
            <div class="line"></div>
            <a href="https://github.com/NilsBoelingen?tab=repositories"
                target="_blank" rel="noreferrer noopener"><img
                    src="assets/img/above_default/git-hub.png"></a>
            <a href="mailto:kontakt@nils-boelingen.de"><img
                    src="assets/img/above_default/e-mail.png"></a>
            <a href="https://www.linkedin.com/in/nils-boelingen" target="_blank"
                rel="noreferrer noopener"><img
                    src="assets/img/above_default/linked-in.png"></a>
            <a href="#contact" class="mail">nils.boelingen&#064;gmail.com</a>
        </div>
        <div class="scroll-down">
            <span>Scroll down</span>
            <img src="assets/img/above_default/arrow-down-line.png">
        </div>
    </div>
    <img src="assets/img/background/Vector.png" class="top-bg-grafik">
</main>
