<main>
    <div class='imprint'><h1>Imprint</h1><p>Information according to § 5 TMG</p><br><p>Nils Boelingen <br> 
        Nordhäuser Str. 4<br> 
        38723 Seesen <br> 
        </p><p> <strong>Represented by: </strong><br><br>
        Nils Boelingen<br>
        </p><p><strong>Kontakt:</strong> <br>
        Telephone: 05381-9417095<br>
        E-Mail: <a href='mailto:kontakt@nils-boelingen.de'>kontakt&#64;nils-boelingen.de</a><br><br></p><p><strong>Disclaimer of liability: </strong><br><br><strong>Liability for content</strong><br><br>
        The contents of our pages have been created with the greatest care. However, we cannot assume any liability for the accuracy, completeness and topicality of the content. 
        As a service provider, we are responsible for our own content on these pages in accordance with § 7 para. 1 TMG (German Telemedia Act) and general laws. 
        According to §§ 8 to 10 TMG, however, we as a service provider are not obliged to monitor transmitted or stored third-party information or to investigate circumstances, 
        that indicate illegal activity. 
        Obligations to remove or block the use of information in accordance with general legislation remain unaffected by this. 
        However, liability in this respect is only possible from the time we become aware of a specific infringement. 
        As soon as we become aware of such infringements, we will remove this content immediately.<br><br>
        <strong>Copyright</strong><br><br>
        The content and works created by the site operators on these pages are subject to German copyright law. 
        Duplication, processing, distribution and any kind of exploitation outside the limits of copyright law require the written consent of the respective author or creator. 
        Downloads and copies of this site are only permitted for private, non-commercial use. 
        Insofar as the content on this site was not created by the operator, the copyrights of third parties are respected. 
        In particular, third-party content is identified as such. 
        Should you nevertheless become aware of a copyright infringement, please notify us accordingly. 
        If we become aware of any infringements, we will remove such content immediately.<br><br>
        <strong>Data protection</strong><br><br>
        The use of our website is generally possible without providing personal data. 
        Insofar as personal data (e.g. name, address or e-mail addresses) is collected on our website, this is always done on a voluntary basis as far as possible. 
        This data will not be passed on to third parties without your express consent. <br>
        We would like to point out that data transmission over the Internet (e.g. when communicating by e-mail) may be subject to security vulnerabilities. 
        Complete protection of data against access by third parties is not possible. <br>
        We hereby expressly prohibit the use of contact data published as part of our duty to provide a legal notice for the purpose of sending unsolicited advertising and information material. 
        The operators of this website expressly reserve the right to take legal action in the event of the unsolicited sending of advertising information, such as spam e-mails.<br>
        </p><br> 
        Imprint from <a href="https://www.impressum-generator.de">Imprint Generator </a> from <a href="https://www.kanzlei-hasselbach.de/">Kanzlei Hasselbach, Lawyers for labor law and family law</a>
         </div>
    <img src="assets/img/imprint/GReen shadow.png" class="grafik-1">
    <img src="assets/img/imprint/Vector 5.png" class="grafik-2">
</main>